export default {
  "app": {
    "로-컬": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로-컬"])},
    "로-컬 숨은 여행지 찾기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローーカル ｜ 隠れた旅行先"])},
    "등록하기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
    "취소하기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])}
  },
  "navbar": {
    "저장": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "공지": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])},
    "건의": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提案"])},
    "로그인": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "로그아웃": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
    "즐겨찾기된 장소가 없습니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りの場所はありません"])}
  },
  "login": {
    "이름": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "비밀번호": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "계정이 없으신가요? 로그인 시 자동으로 생성됩니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインすると自動的に生成されます。"])}
  },
  "tag": {
    "음식": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食べ物"])},
    "관광": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["観光"])},
    "모험": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冒険"])},
    "체험": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体験"])},
    "숙소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊"])},
    "축제": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お祭り"])}
  },
  "search": {
    "검색어를 입력하세요": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索ワード"])},
    "검색된 장소가 없습니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索結果がありません"])}
  },
  "side-tab": {
    "로딩": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローディング..."])}
  },
  "feedback": {
    "여기에 글을 작성하세요": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここに入力してください…"])},
    "익명의 모험가": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From. 匿名の冒険者"])},
    "취소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "보내기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])}
  },
  "new-place": {
    "취소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "이전": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前へ"])},
    "다음": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
    "완료": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
    "사진": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真"])},
    "이름": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "설명": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
    "사진 3장 이하로!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真は3枚以下で！"])},
    "필수 입력입니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須項目です"])}
  },
  "import-place": {
    "가져오기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インポート"])},
    "취소": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "선택": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
    "구글 지도에서 보기": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleマップで見る"])}
  },
  "toast": {
    "로그인 실패!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインに失敗しました！"])},
    "로그인이 필요합니다": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインが必要です"])},
    "건의할 내용을 입력해주세요": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提案内容を入力してください"])},
    "피드백 감사합니다!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックありがとうございます！"])}
  }
}