<template>
  <div class="default-tab">
    <img :src="currentBannerImage" alt="Signboard" class="banner-image" />
    <h2 class="banner-quote">
      {{ currentBannerText.quote }}
    </h2>
    <h3 class="banner-author">{{ currentBannerText.author }}</h3>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const currentBannerImage = ref("");
const currentBannerText = ref("");

onMounted(() => {
  const randomIndex = Math.floor(Math.random() * bannerImages.length);
  currentBannerImage.value = bannerImages[randomIndex];

  const randomIndex2 = Math.floor(
    Math.random(randomIndex) * bannerTexts.length
  );
  currentBannerText.value = bannerTexts[randomIndex2];
});

const bannerImages = [
  require("@/assets/pixelarts/climb_mountain.webp"),
  require("@/assets/pixelarts/meet_oasis_1.webp"),
  require("@/assets/pixelarts/meet_oasis_2.webp"),
  require("@/assets/pixelarts/sailboat.webp"),
];

const bannerTexts = [
  {
    quote: "여행이란, 다른 사람이 사는 방식을 배우는 것이다.",
    author: "- 로저 크릭",
  },
  { quote: "여행은 당신을 말없이 변하게 한다.", author: "- 앤서니 부르댕" },
  { quote: "모든 여행은 새로운 시작의 서막이다.", author: "- 누군가" },
  {
    quote: "여행은 우리가 겪을 수 있는 최고의 모험이다.",
    author: "- 어떤 모험가",
  },
  {
    quote:
      "여행은 당신을 겸손하게 만든다. 당신이 세상에서 얼마나 작은 존재인지를 깨닫게 된다.",
    author: "- 구스타브 플로베르",
  },
  {
    quote: "여행은 그 자체로 충분한 보상이 된다.",
    author: "- 어네스트 헤밍웨이",
  },
  {
    quote:
      "세상은 한 권의 책이며, 여행하지 않는 자는 그 책의 한 페이지만 읽는 것이다.",
    author: "- 성 아우구스티누스",
  },
  { quote: "여행은 당신을 더 나은 이야기꾼으로 만든다.", author: "- 누군가" },
  { quote: "여행은 우리의 마음을 넓혀준다.", author: "- 마크 트웨인" },
  { quote: "여행은 교육의 한 부분이다.", author: "- 프랜시스 베이컨" },
  { quote: "여행은 당신의 인생에 색을 더해준다.", author: "- 어떤 여행가" },
  {
    quote: "여행은 당신에게 인생의 새로운 의미를 부여한다.",
    author: "- 누군가",
  },
  { quote: "여행은 꿈을 실현하는 것이다.", author: "- 파울로 코엘료" },
  {
    quote: "여행은 모든 것을 새롭게 보는 것이다.",
    author: "- 마르셀 프루스트",
  },
  {
    quote: "모험이 없다면 인생은 절반의 의미를 잃는다.",
    author: "- 헬렌 켈러",
  },
  {
    quote: "여행은 답을 찾기 위한 것이 아니라 질문을 발견하기 위한 것이다.",
    author: "- 누군가",
  },
  {
    quote: "새로운 경험은 우리에게 더 넓은 시야와 깊은 이해를 선사한다.",
    author: "- 알베르트 아인슈타인",
  },
  {
    quote:
      "우리가 가진 유일한 진짜 여행은 다른 이의 눈으로 세상을 바라보는 것이다.",
    author: "- 마르셀 프루스트",
  },
  {
    quote: "여행은 단순한 휴식이 아니라 삶의 본질을 찾기 위한 탐구이다.",
    author: "- 로버트 루이스 스티븐슨",
  },
  {
    quote: "위험을 감수하지 않는다면, 인생은 의미를 잃는다.",
    author: "- 아미엘",
  },
  {
    quote: "여행은 우리의 영혼을 풍요롭게 하고, 세상을 더 나은 곳으로 만든다.",
    author: "- 존 스테인벡",
  },
  {
    quote:
      "진정한 탐험은 새로운 땅을 발견하는 것이 아니라, 새로운 시각으로 세상을 보는 것이다.",
    author: "- 마르셀 프루스트",
  },
  {
    quote: "모험이 없으면 인생은 반복되는 일상의 연속일 뿐이다.",
    author: "- T.S. 엘리엇",
  },
  {
    quote:
      "새로운 길을 두려워하지 말라. 그 길은 당신의 인생을 풍요롭게 할 것이다.",
    author: "- 누군가",
  },
  {
    quote: "여행은 자신의 한계를 시험하고, 그 한계를 넘는 과정이다.",
    author: "- 리처드 프랜시스 버튼",
  },
  { quote: "삶이란 모험 아니면 아무것도 아니다.", author: "- 헬렌 켈러" },
  {
    quote: "모험을 통해 우리는 진정한 자신을 발견한다.",
    author: "- 앙드레 지드",
  },
  {
    quote: "여행은 우리를 더 나은 사람으로 만들어준다.",
    author: "- 마야 안젤루",
  },
  {
    quote: "우리가 두려워하는 것은 새로운 길이 아니라, 새로운 길에서의 실패다.",
    author: "- 파울로 코엘료",
  },
  {
    quote: "모험이란 새로운 세상을 향한 두려움 없는 첫 걸음이다.",
    author: "- 루이스 라무르",
  },
  {
    quote: "여행은 삶의 연속적인 모험이며, 매 순간이 새로운 배움이다.",
    author: "- 누군가",
  },
  { quote: "모험은 위험이 아니라, 기회다.", author: "- 크리스토퍼 맥캔들리스" },
  {
    quote: "삶은 당신의 안전지대를 벗어나야 비로소 시작된다.",
    author: "- 닐 도날드 월쉬",
  },
  {
    quote:
      "소중한 것을 깨닫는 장소는 언제나 컴퓨터 앞이 아니라 파란 하늘 아래였다.",
    author: "- 다카하시 아유무",
  },
  {
    quote: "가장 기쁜 순간들이란, 내가 생각하기엔 미지의 세상으로의 출발이다.",
    author: "- 리차드 버튼",
  },
  {
    quote:
      "여행이란 우리가 사는 장소를 바꿔주는 것이 아니라 우리의 생각과 편견을 바꿔주는 것이다.",
    author: "- 아나톨 프랑스",
  },
  {
    quote:
      "여행의 목적지가 다르다고 해도 되돌아오는 곳은 다 같다. 바로 자기 자신이다. 여행은 곧 자기 자신을 기억하는 행위.",
    author: "- 안치운, <그리움으로 걷는 옛길>",
  },
  {
    quote:
      "여행할 장소에 대한 조언은 어디에나 널려 있지만, 우리가 가야 하는 이유와 가는 방법에 대한 이야기는 듣기 힘들다.",
    author: "- 알랭 드 보통, <여행의 기술>",
  },
  {
    quote:
      "여행을 많이 해서 자신의 생각과 삶의 형태를 여러 번 바꿔 본 사람보다 더 완전한 사람은 없다.",
    author: "- 알퐁스 드 라마르틴",
  },
  {
    quote:
      "목적지에 닿아야 행복해지는 것이 아니라 여행하는 과정에서 행복을 느낀다.",
    author: "- 앤드류 매튜스",
  },
  {
    quote: "여행이란 내가 있던 자리를 떠나 내가 있던 자리를 보는 일이다.",
    author: "- 오소희, <바람이 우리를 데려다주겠지>",
  },
  {
    quote:
      "익숙한 삶에서 벗어나 현지인들과 만나는 여행은 생각의 근육을 단련하는 비법이다.",
    author: "- 이노우에 히로유키",
  },
  {
    quote:
      "여행은 시간을 들이는 일이라고 생각하기 쉽지만, 내게 있어 여행은 시간을 벌어오는 일이었다.",
    author: "- 이병률, <바람이 분다 당신이 좋다>",
  },
  {
    quote:
      "여행은 길을 찾는 데 꼭 필요한 도구들이 들어있는 숨겨 놓은 선물 상자 같은 것.",
    author: "- 이애경, <눈물을 그치는 타이밍>",
  },
  {
    quote:
      "우리는 아름답고 매력적이고 모험으로 가득 찬 멋진 세상에서 살고 있어요. 오직 우리가 눈을 뜨고 찾기만 한다면 우리가 할 수 있는 모험은 끝이 없습니다.",
    author: "- 자와할랄 네루",
  },
  {
    quote:
      "우리 여행이 일탈, 신기루를 붙잡으려는 헛된 시도, 한 모금의 사사로운 행복을 찾는 도피라고 해도 좋아요.",
    author: "- 장석주, <내 몫의 사랑을 탕진하고 지금 당신을 만나>",
  },
  {
    quote:
      "여행은 이렇게 ‘나만 즐기고 싶은 세상’이 아니라 ‘함께 누리고 싶은 세상’을 향해서 별의별 꿈을 다 꾸게 한다.",
    author: "- 정여울, <내가 사랑한 유럽 TOP 10>",
  },
  {
    quote: "직업은 주머니를 가득 채우지만, 모험은 당신의 영혼을 채워줍니다.",
    author: "- 제이미 린 비티",
  },
  {
    quote:
      "인간은 자신이 필요로 하는 것을 찾아 세계를 여행하고 집에 돌아와 그것을 발견한다.",
    author: "- 조지 무어",
  },
  {
    quote:
      "여행은 사소한 것을 발견하는 행위, 우리가 몰랐던 것을 새롭게 알게 되는 기회죠.",
    author: "- 최갑수, <사랑보다도 더 사랑한다는 말이 있다면>",
  },
  { quote: "순간들을 모아라, 물건들이 아니라.", author: "- 카렌 살만손" },
  {
    quote:
      "우리는 익숙한 것에 대한 향수와 낯설고 이국적인 것에 대한 욕구 사이에서 방황한다. 그리고 가끔 우리는 전혀 모르는 곳에 대해 향수병을 앓는다.",
    author: "- 카슨 매컬러스",
  },
  {
    quote:
      "여행은 도시와 시간을 이어주는 일이다. 내게 가장 아름답고 철학적인 여행은 그런 멈춤들 사이에 있다.",
    author: "- 폴 발레리",
  },
  {
    quote:
      "여행은 사라지는 것, 홀로 좁은 지형을 따라 세상에서 자취를 감추는 행위이다.",
    author: "- 폴 서루",
  },
  {
    quote:
      "우리는 처음 자기 자신을 잃기 위해 여행을 한다. 그러고 나서 우린 자기 자신을 찾기 위해 여행을 한다.",
    author: "- 피코 아이어",
  },
  {
    quote:
      "여행은 다른 문화, 다른 사람을 만나고 결국에는 자기 자신을 만나는 것이다.",
    author: "- 한비야",
  },
  {
    quote:
      "여행의 목적은 특정의 장소가 아니라, 사물을 바라 보는 새로운 시각이다.",
    author: "- 헨리 밀러",
  },
  {
    quote:
      "배우고자 하는 사람은 먼저 책을 읽고, 배운 것을 바로잡기 위해 여행을 떠나야 한다.",
    author: "- 자코모 카사노바",
  },
  {
    quote:
      "자유가 없는 세상에 맞서는 유일한 방법은 자신의 존재를 반란의 행위로 만들 정도로 절대적으로 자유로워지는 것입니다.",
    author: "- 알베르 카뮈",
  },
  {
    quote: "하지만 진정한 여행자는 홀로 여행을 떠나는 사람입니다.",
    author: "- 샤를 보들레르",
  },
  { quote: "여행만큼 지능을 발달시키는 것은 없습니다.", author: "- 에밀 졸라" },
  {
    quote: "고국을 떠나지 않은 사람은 편견으로 가득 차 있다.",
    author: "- 카를로 골도니",
  },
  {
    quote:
      "보트가 가장 안전한 곳은 항구입니다. 하지만 보트는 그런 용도로 만들어진 것이 아니니까요.",
    author: "- 익명",
  },
  {
    quote:
      "전 세계 모든 여행자들은 거리에서 미적, 정치적, 신비적, 경제적 측면에서 한 나라의 정체성을 느낄 수 있다는 것을 알고 있습니다.",
    author: "- 마누 차오",
  },
  {
    quote:
      "물론 도넛 꿈은 도넛이 아니라 꿈일 뿐입니다. 하지만 여행에 대한 꿈은 이미 여행입니다.",
    author: "- 마렉 할터",
  },
  {
    quote: "다른 사람의 뇌를 문지르고 정리하려면 여행을 떠나야 합니다.",
    author: "- 몽테뉴",
  },
  {
    quote: "세상은 한 권의 책이고 여행하지 않는 사람은 한 페이지만 읽는다.",
    author: "- 성 어거스틴",
  },
  {
    quote: "더 멀리 여행할수록 서로에 대해 더 적게 알게 된다.",
    author: "- 노자",
  },
  {
    quote:
      "여행을 떠나기 전, 내일의 지평선이 우리를 찾아와 그 약속을 들려주는 순간보다 더 아름다운 순간은 없습니다.",
    author: "- 밀란 쿤데라",
  },
  {
    quote:
      "진정한 발견의 항해는 새로운 풍경을 찾는 것이 아니라 새로운 눈을 갖는 것입니다.",
    author: "- 마르셀 프루스트",
  },
  {
    quote:
      "하루가 끝나면 사무실에서 보낸 시간이나 잔디를 깎은 시간은 기억나지 않을 테니까요. 가서 산에 올라가세요.",
    author: "- 잭 케루악",
  },
  {
    quote: "그녀가 도망칠 때, 길은 따라갈 가치가 있는 유일한 연인이다.",
    author: "- 실뱅 테송",
  },
  {
    quote: "여행을 몇 번 다녀오면 생각의 폭이 넓어지고 자존감도 높아집니다.",
    author: "- 생트부아브",
  },
  {
    quote: "진정한 여행자는 자신이 어디로 가는지 모른다.",
    author: "- 중국 속담",
  },
  {
    quote: "사람이 여행을 만드는 것이 아니라 여행이 사람을 만든다.",
    author: "- 존 스타인벡",
  },
  {
    quote:
      "여행을 하면 겸손해지고, 자신이 세상에서 얼마나 작은 자리를 차지하고 있는지 알게 됩니다.",
    author: "- 구스타브 플로베르",
  },
  {
    quote:
      "지금부터 20년 후에는 자신이 한 일보다 하지 않은 일에 더 실망하게 될 것입니다. 그러니 상자 밖에서 생각하세요. 항해하세요. 탐험하세요. 꿈꾸고. 발견하세요.",
    author: "- 마크 트웨인",
  },
  {
    quote: "삶은 숨을 쉬는 것뿐만 아니라 숨이 멎는 것까지 포함합니다.",
    author: "- 알프레드 히치콕",
  },
  {
    quote: "여행하기 위해 사는 것이 아니라, 살기 위해 여행하라!",
    author: "- 익명",
  },
  { quote: "여행은 매 순간 태어나고 죽는 일이다.", author: "- 빅토르 위고" },
  {
    quote: "인생의 슬픔을 이겨내는 데는 여행이 최고의 치료제가 아닐까요?",
    author: "- 미셸 투르니에",
  },
  {
    quote: "첫 번째 여행에서는 발견하고, 두 번째 여행에서는 풍요로워집니다.",
    author: "- 투아레그 속담",
  },
  {
    quote:
      "상대방을 만나지 않고 여행하는 사람은 여행하는 것이 아니라 움직이는 것이다.",
    author: "- 알렉산드라 데이비드 넬",
  },
  {
    quote:
      "길 위에서 가장 좋은 것은 길을 잃는 것입니다. 길을 잃으면 계획은 예상치 못한 상황으로 바뀌고, 그제야 비로소 여행이 시작됩니다.",
    author: "- 니콜라스 부비에",
  },
  {
    quote: "모험이 위험하다고 생각하신다면 루틴을 추천합니다…. 치명적이니까요.",
    author: "- 파울로 코엘료",
  },
  {
    quote:
      "여행은 다른 사람을 발견하는 것입니다. 그리고 가장 먼저 발견하는 낯선 사람은 바로 나 자신입니다.",
    author: "- 올리버 푈미",
  },
  {
    quote: "떠나기로 결심하기만 하면 됩니다. 어려운 일은 끝났습니다.",
    author: "- 토니 휠러",
  },
  {
    quote: "머무른다는 것은 존재한다는 것이다. 여행하는 것은 사는 것이다.",
    author: "- 구스타브 나우다드",
  },
  {
    quote: "우리는 우리의 자리가 아니라 아이디어를 바꾸기 위해 여행합니다.",
    author: "- 히폴리테 테인",
  },
  {
    quote:
      "우리는 여행을 만드는 것이 아닙니다. 여행은 우리를 만들고, 우리를 만들고, 우리를 창조합니다.",
    author: "- 데이비드 르 브르타뉴",
  },
  {
    quote:
      "여행은 자신으로부터 도망치기 위해 떠나는 것이 아니라 자신을 찾기 위해 떠나는 것이다.",
    author: "- 장 그레니에",
  },
  {
    quote: "현대인의 가장 큰 불행 중 하나는 예상치 못한 일, 모험의 부재입니다.",
    author: "- 테오필 고티에",
  },
  {
    quote: "여행은 젊음의 교육이자 노년의 경험이다.",
    author: "- 프랜시스 베이컨",
  },
  {
    quote:
      "일반적으로 여행자란 무엇인가요? 세상의 끝에서 대화의 소재를 찾아 떠나는 사람입니다.",
    author: "- 바베이 뒤레빌리",
  },
  {
    quote:
      "빌보르데에 살면서 홍콩에 가서 살고 싶은 사람에게 가장 힘든 일은 홍콩에 가는 것이 아니라 빌보르데를 떠나는 것입니다.",
    author: "- 자크 브렐",
  },
  {
    quote:
      "여행에 익숙한 사람이라면… 언젠가는 떠나야 할 때가 온다는 것을 알고 있습니다.",
    author: "- 파울로 코엘료",
  },
  {
    quote:
      "여행은 마치 꿈처럼 보이는 미지의 현실로 들어가기 위해 현실을 떠나는 일종의 문입니다.",
    author: "- 기 드 모파상",
  },
  {
    quote: "여행자는 자신이 보는 것을 보고, 관광객은 자신이 보러 온 것을 본다.",
    author: "- 길버트 키스 체스터턴",
  },
  { quote: "여행은 기본으로 돌아가는 것입니다.", author: "- 티베트 속담" },
  {
    quote:
      "여행은 불성실하게 행동하는 것이다. 후회 없이 여행하고 낯선 사람과의 친구는 잊어버리세요.",
    author: "- 폴 모란드",
  },
];
</script>

<style scoped>
.default-tab {
  padding: 16px;
}

h2,
h3 {
  line-height: 1.5;
  font-family: "subset-DOSIyagiBoldface";
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 3px;
}

.banner-quote {
  word-break: keep-all;
}

.banner-author {
  text-align: right;
}
</style>
